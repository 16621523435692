/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress
} from "reactstrap";

// Firestore
import { firestore } from "./../../../firebase";

import {
  chartOptions
} from "variables/charts.js";

// Auth actions
import {
  SET_CUSTOMERS
} from 'store/actions';

// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Persona break down
let personaDataDetails = {
  conversions: {},
  costPerAcquisition: {},
  revenueLift: {}
};

// Chart data placeholders
let chartData = {
  conversions: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Campaign Conversions",
            data: values,
          }
        ]
      };
    }
  },
  costPerAcquisition: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Cost per Acquisition",
            data: values,
          }
        ]
      };
    }
  },
  revenueLift: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Projected Revenue Lift",
            data: values,
          }
        ]
      };
    }
  }
};

// Query: Persona Performance
async function getPersonaPerformance(account, campaign, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Format query
  let personaPerformanceQuery = {
    "events": [
          {
              "id": "Customer Activity",
              "math": "dau",
              "name": "Customer Activity",
              "type": "events",
              "order": 0,
              "properties": [
                  {
                      "key": "type",
                      "type": "event",
                      "value": [
                          "Campaign Conversion"
                      ],
                      "operator": "exact"
                  },
                  {
                      "key": "properties.campaign.version = '0.0.2'",
                      "type": "hogql",
                      "value": null
                  },
                  {
                      "key": "clientId",
                      "type": "event",
                      "value": [
                          `${account.active.id}`
                      ],
                      "operator": "exact"
                  },
                  ...(campaign ? [{
                    "key": `properties.campaign.id = '${campaign.data.campaign_id}'`,
                    "type": "hogql",
                    "value": null
                  }] : [])
              ],
              "custom_name": "Persona Conversions"
          },
          {
              "id": "Customer Activity",
              "math": "hogql",
              "name": "Customer Activity",
              "type": "events",
              "order": 1,
              "math_hogql": "sum(toFloat(properties.campaign.ad.revenue_lift))",
              "properties": [
                  {
                      "key": "type",
                      "type": "event",
                      "value": [
                          "Campaign Conversion"
                      ],
                      "operator": "exact"
                  },
                  {
                      "key": "properties.campaign.version = '0.0.2'",
                      "type": "hogql",
                      "value": null
                  },
                  {
                      "key": "clientId",
                      "type": "event",
                      "value": [
                          `${account.active.id}`
                      ],
                      "operator": "exact"
                  },
                  ...(campaign ? [{
                    "key": `properties.campaign.id = '${campaign.data.campaign_id}'`,
                    "type": "hogql",
                    "value": null
                  }] : [])
              ],
              "custom_name": "Persona Revenue Lift"
          },
          {
              "id": "Customer Activity",
              "math": "hogql",
              "name": "Customer Activity",
              "type": "events",
              "order": 2,
              "math_hogql": "avg(toFloat(properties.campaign.ad.cac))",
              "properties": [
                  {
                      "key": "type",
                      "type": "event",
                      "value": [
                          "Campaign Conversion"
                      ],
                      "operator": "exact"
                  },
                  {
                      "key": "properties.campaign.version = '0.0.2'",
                      "type": "hogql",
                      "value": null
                  },
                  {
                      "key": "clientId",
                      "type": "event",
                      "value": [
                          `${account.active.id}`
                      ],
                      "operator": "exact"
                  },
                  ...(campaign ? [{
                    "key": `properties.campaign.id = '${campaign.data.campaign_id}'`,
                    "type": "hogql",
                    "value": null
                  }] : [])
              ],
              "custom_name": "Persona CAC"
          }
      ],
      "date_to": endDate,
      "display": "ActionsLineGraph",
      "insight": "TRENDS",
      "interval": "week",
      "breakdown": "properties.persona.name",
      "date_from": startDate,
      "entity_type": "events",
      "breakdown_type": "hogql"
  };

  // Execute query
  const personaPerformanceResponse = await axios.post(eventsApiUrl, personaPerformanceQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });
  console.log(" - Campaign Performance Query Results: ", personaPerformanceResponse);

  // Split results
  let conversions = [];
  let costPerAcquisition = [];
  let revenueLift = [];
  for (var i = 0; i < personaPerformanceResponse.data.result.length; i++) {
    // Grab value
    let campaignStat = personaPerformanceResponse.data.result[i];

    // Check: Conversions
    if(campaignStat.action.custom_name == "Persona Conversions") {
      conversions.push({
        label: (campaignStat.breakdown_value.indexOf("null") > 0) ? "(None)" : campaignStat.breakdown_value,
        data: campaignStat.data,
        days: campaignStat.days,
        daysAbrev: campaignStat.labels
      });
    }

    // Check: Cost per Acquisition
    if(campaignStat.action.custom_name == "Persona CAC") {
      costPerAcquisition.push({
        label: (campaignStat.breakdown_value.indexOf("null") > 0) ? "(None)" : campaignStat.breakdown_value,
        data: campaignStat.data,
        days: campaignStat.days,
        daysAbrev: campaignStat.labels
      });
    }

    // Check: Revenue Lift
    if(campaignStat.action.custom_name == "Persona Revenue Lift") {
      revenueLift.push({
        label: (campaignStat.breakdown_value.indexOf("null") > 0) ? "(None)" : campaignStat.breakdown_value,
        data: campaignStat.data,
        days: campaignStat.days,
        daysAbrev: campaignStat.labels
      });
    }
  }

  return {
    conversions: conversions,
    costPerAcquisition: costPerAcquisition,
    revenueLift: revenueLift
  };
}

// Query: Customer (by Persona)
async function getCustomers(account, campaign, personaName, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/query`;

  // Get all current customers
  let customerActivityQuery = { 
    "query": {
      "kind": "HogQLQuery",
      "query": `
        WITH
            filtered_events AS (
                SELECT
                    person_id,
                    properties.persona.name AS persona_name
                FROM events AS e
                WHERE (team_id = 61350) 
                AND (event = 'Customer Activity') 
                AND (properties.type = 'Campaign Conversion')
                AND (properties.campaign.version = '0.0.1')
                AND (toTimeZone(timestamp, 'UTC') >= toDateTime('2024-03-29 00:00:00', 'UTC')) 
                AND (toTimeZone(timestamp, 'UTC') <= toDateTime('2024-05-31 23:59:59', 'UTC'))
                ${(personaName != 'all') ? `AND (properties.persona.name = '${personaName}')` : `AND (properties.persona.name != null)`}
                ${campaign ? `AND (properties.campaign.id = '${campaign.data.campaign_id}')` : ''}
            )

        SELECT DISTINCT
            d.distinct_id,
            e.persona_name,
            p.properties.first_name,
            p.properties.last_name,
            p.properties.lifetime_value,
            p.properties.average_order_value
        FROM persons p
        JOIN filtered_events e ON p.id = e.person_id
        JOIN person_distinct_ids d ON d.person_id = p.id
        ORDER BY p.properties.lifetime_value DESC
        LIMIT 10
      `
    }
  }

  // Execute query
  const customerActivityResponse = await axios.post(eventsApiUrl, customerActivityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Return values
  return {
    customers: customerActivityResponse.data.results
  };
}


function CampaignsChart({ campaign, profile, account, report, dispatch }) {
  // Check selected campaign
  if(campaign) {
    console.log(" - Loading Campaign Chart:", campaign);
  }

  // Default states
  const [ personaData, setPersonaData ] = React.useState([]);
  const [ activeChart, setActiveChart ] = React.useState("conversions");
  const [ activeChartData, setActiveChartData ] = React.useState("default");

  // Set KPI values
  const [ totalConversions, setTotalConversions ] = React.useState(0);
  const [ costPerAcquisition, setCostPerAcquisition ] = React.useState(0.0);
  const [ revenueLift, setRevenueLift ] = React.useState(0.0);

  // Set macro values
  const [ totalConversionsAll, setTotalConversionsAll ] = React.useState(0);
  const [ costPerAcquisitionAll, setCostPerAcquisitionAll ] = React.useState(0.0);
  const [ revenueLiftAll, setRevenueLiftAll ] = React.useState(0.0);

  // Load persona data
  React.useEffect(() => {
    // Campaign Setup
    if (account.active) {
      // Sync Campaign Data
      (async () => {
        // Get persona perforamnce
        const personaPerformance = await getPersonaPerformance(account, campaign, report.filters.timeframe.startDate, report.filters.timeframe.setEndDate);
        console.log("Persona Performance: ", personaPerformance);

        /*
         *  [1] Update Chart: Conversions
         */
        let conversionData = [];
        let conversionLabels = [];
        let totalPersonaConversions = 0;
        for(var i = 0; i < personaPerformance.conversions.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < personaPerformance.conversions[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              conversionData[j] += personaPerformance.conversions[i].data[j];
            } else {
              // Set defaults
              conversionData = personaPerformance.conversions[i].data;
              conversionLabels = personaPerformance.conversions[i].days;
            }
          }

          // Update persona stats
          if(personaPerformance.conversions[i].label !== "(None)") {
            // Update data details
            personaDataDetails['conversions'][personaPerformance.conversions[i].label] = {
              name: personaPerformance.conversions[i].label,
              total: _.sum(personaPerformance.conversions[i].data)
            };

            // Increment total
            totalPersonaConversions += _.sum(personaPerformance.conversions[i].data);
          }

          // Update chart
          let personaConversionData = personaPerformance.conversions[i].data;
          chartData.conversions[personaPerformance.conversions[i].label] = (canvas) => {
            return {
              labels: conversionLabels,
              datasets: [{
                label: "Conversions",
                data: personaConversionData
              }]
            };
          };
        }

        // Update chart
        chartData.conversions['all'] = (canvas) => {
          return {
            labels: conversionLabels,
            datasets: [{
              label: "Conversions",
              data: conversionData
            }]
          };
        };

        // Update stats
        setTotalConversions(_.sum(conversionData));
        setTotalConversionsAll(_.sum(conversionData));

        // Update persona data
        for(const personaName in personaDataDetails['conversions']) {
          // Update total
          personaDataDetails['conversions'][personaName]['strength'] = 100.0 * personaDataDetails['conversions'][personaName]['total'] / totalPersonaConversions;
        }

        /*
         *  [2] Update Chart: Cost per Acquisition
         */
        let costPerAcquisitionData = [];
        let costPerAcquisitionLabels = [];
        for(var i = 0; i < personaPerformance.costPerAcquisition.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < personaPerformance.costPerAcquisition[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              costPerAcquisitionData[j] += personaPerformance.costPerAcquisition[i].data[j];
            } else {
              // Set defaults
              costPerAcquisitionData = personaPerformance.costPerAcquisition[i].data;
              costPerAcquisitionLabels = personaPerformance.costPerAcquisition[i].days;
            }
          }

          // Update persona stats
          if(personaPerformance.costPerAcquisition[i].label !== "(None)") {
            // Update data details
            personaDataDetails['costPerAcquisition'][personaPerformance.costPerAcquisition[i].label] = {
              name: personaPerformance.costPerAcquisition[i].label,
              total: _.sum(personaPerformance.costPerAcquisition[i].data) / personaPerformance.costPerAcquisition[i].data.length
            };
          }

          // Update chart
          let personaCostPerAcquisitionData = personaPerformance.costPerAcquisition[i].data;
          chartData.costPerAcquisition[personaPerformance.costPerAcquisition[i].label] = (canvas) => {
            return {
              labels: costPerAcquisitionLabels,
              datasets: [{
                label: "Cost per Acquisition",
                data: personaCostPerAcquisitionData
              }]
            };
          };
        }

        // Average results
        for(var i = 0; i < costPerAcquisitionData.length; i++) {
          // Average
          costPerAcquisitionData[i] /= personaPerformance.costPerAcquisition.length;
        }

        // Update chart
        chartData.costPerAcquisition['all'] = (canvas) => {
          return {
            labels: costPerAcquisitionLabels,
            datasets: [{
              label: "Cost per Acquisition",
              data: costPerAcquisitionData
            }]
          };
        };

        // Update stats
        setCostPerAcquisition(_.sum(costPerAcquisitionData) / costPerAcquisitionData.length);
        setCostPerAcquisitionAll(_.sum(costPerAcquisitionData) / costPerAcquisitionData.length);

        // Update persona data
        for(const personaName in personaDataDetails['costPerAcquisition']) {
          // Update total
          personaDataDetails['costPerAcquisition'][personaName]['strength'] = 100.0 * personaDataDetails['costPerAcquisition'][personaName]['total'] / (_.max(costPerAcquisitionData));
        }

        /*
         *  [3] Update Chart: Revenue Lift
         */
        let revenueLiftData = [];
        let revenueLiftLabels = [];
        let totalPersonaRevenueLift = 0;
        for(var i = 0; i < personaPerformance.revenueLift.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < personaPerformance.revenueLift[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              revenueLiftData[j] += personaPerformance.revenueLift[i].data[j];
            } else {
              // Set defaults
              revenueLiftData = personaPerformance.revenueLift[i].data;
              revenueLiftLabels = personaPerformance.revenueLift[i].days;
            }
          }

          // Update persona stats
          if(personaPerformance.revenueLift[i].label !== "(None)") {
            // Update data details
            personaDataDetails['revenueLift'][personaPerformance.revenueLift[i].label] = {
              name: personaPerformance.revenueLift[i].label,
              total: _.sum(personaPerformance.revenueLift[i].data)
            };

            // Increment total
            totalPersonaRevenueLift += _.sum(personaPerformance.revenueLift[i].data);
          }

          // Update chart
          let personaRevenueLiftData = personaPerformance.revenueLift[i].data;
          chartData.revenueLift[personaPerformance.revenueLift[i].label] = (canvas) => {
            return {
              labels: revenueLiftLabels,
              datasets: [{
                label: "Revenue Lift",
                data: personaRevenueLiftData
              }]
            };
          };
        }

        // Update chart
        chartData.revenueLift['all'] = (canvas) => {
          return {
            labels: revenueLiftLabels,
            datasets: [{
              label: "Revenue Lift",
              data: revenueLiftData
            }]
          };
        };

        // Update stats
        setRevenueLift(_.sum(revenueLiftData));
        setRevenueLiftAll(_.sum(revenueLiftData));

        // Update persona data
        for(const personaName in personaDataDetails['revenueLift']) {
          // Update total
          personaDataDetails['revenueLift'][personaName]['strength'] = 100.0 * personaDataDetails['revenueLift'][personaName]['total'] / totalPersonaRevenueLift;
        }

        // Set chart
        console.log("Chart Data: ", chartData);
        setActiveChartData('all');

        // Set persona data
        let updatedPersonaData = [];
        console.log("Persona Data Details: ", personaDataDetails[activeChart]);
        for(const personaName in personaDataDetails[activeChart]) {
          // Update total
          updatedPersonaData.push(personaDataDetails[activeChart][personaName])
        }
        setPersonaData(updatedPersonaData);

        /*
         *  [4] Load Initial Customer Data
         */

        // Populate customer details
        const customerDetails = await getCustomers(account, campaign, 'all', "2024-03-29", "2024-05-31T23:59:59");
        const customerIDs = _.map(customerDetails.customers, function(x) { return x[0]; });
        console.log("Customer Personas (List): ", customerDetails.customers);

        // Load mapped customer data
        let querySnapshot = await firestore.collection("customers_v0.9").where('id', 'in', customerIDs).get();
        let customers = [];
        querySnapshot.forEach((doc) => {
          customers.push(doc.data());
        });

        // Initialize insights
        dispatch({
          type: SET_CUSTOMERS,
          payload: customers.slice(0, 10)
        });
      })();
    }
  }, [ account.active ]);

  // Handle chart click
  const selectChart = async (chartName) => {
    // Update chart
    setActiveChart(chartName);

    // Set persona data
    let updatedPersonaData = [];
    for(const personaName in personaDataDetails[chartName]) {
      // Update total
      updatedPersonaData.push(personaDataDetails[chartName][personaName])
    }
    setPersonaData(updatedPersonaData);
    console.log(" - Updated Persona Data: ", updatedPersonaData, chartName, personaDataDetails);

    // Update KPI's
    setTotalConversions((activeChartData == 'all') ? totalConversionsAll : personaDataDetails['conversions'][activeChartData]['total']);
    setCostPerAcquisition((activeChartData == 'all') ? costPerAcquisitionAll : personaDataDetails['costPerAcquisition'][activeChartData]['total']);
    setRevenueLift((activeChartData == 'all') ? revenueLiftAll : personaDataDetails['revenueLift'][activeChartData]['total']);
  }

  // Handle chart click
  const selectPersona = async (selectedPersonaName) => {
    // Clear Customers
    dispatch({
      type: SET_CUSTOMERS,
      payload: []
    });

    /*
     *  [1] Update Chart: Conversions
     */

    // Update chart
    setActiveChartData((selectedPersonaName == activeChartData) ? 'all' : selectedPersonaName);

    // Set persona data
    let updatedPersonaData = [];
    for(const personaName in personaDataDetails[activeChart]) {
      // Update total
      updatedPersonaData.push(personaDataDetails[activeChart][personaName])
    }
    setPersonaData(updatedPersonaData);
    console.log(" - Updated Persona Data: ", updatedPersonaData, activeChart, personaDataDetails);

    // Update KPI's
    setTotalConversions((selectedPersonaName == activeChartData) ? totalConversionsAll : personaDataDetails['conversions'][selectedPersonaName]['total']);
    setCostPerAcquisition((selectedPersonaName == activeChartData) ? costPerAcquisitionAll : personaDataDetails['costPerAcquisition'][selectedPersonaName]['total']);
    setRevenueLift((selectedPersonaName == activeChartData) ? revenueLiftAll : personaDataDetails['revenueLift'][selectedPersonaName]['total']);

    /*
     *  [2] Load Updated Customer Data
     */

    // Populate customer details
    const customerDetails = await getCustomers(account, campaign, selectedPersonaName, "2024-03-29", "2024-05-31T23:59:59");
    const customerIDs = _.map(customerDetails.customers, function(x) { return x[0]; });
    console.log("Customer Personas (List): ", customerDetails.customers);

    // Load mapped customer data
    let querySnapshot = await firestore.collection("customers_v0.9").where('id', 'in', customerIDs).get();
    let customers = [];
    querySnapshot.forEach((doc) => {
      customers.push(doc.data());
    });

    // Initialize insights
    dispatch({
      type: SET_CUSTOMERS,
      payload: customers.slice(0, 10)
    });
  }

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Customer Intelligence
                </h6>
                <h5 className="h3 text-white mb-0">Campaign Performance</h5>
              </div>
              <div className="col">

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'conversions' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('conversions')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'conversions' ? `text-primary` : 'text-white'}`}
                        >
                          Total Conversions
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'conversions' ? `` : 'text-white'}`}>{totalConversions}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'costPerAcquisition' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('costPerAcquisition')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'costPerAcquisition' ? `text-primary` : 'text-white'}`}
                        >
                          Cost per Acquisition
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'costPerAcquisition' ? `` : 'text-white'}`}>{accounting.formatMoney(costPerAcquisition)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'revenueLift' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('revenueLift')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'revenueLift' ? `text-primary` : 'text-white'}`}
                        >
                          Projected Revenue Lift
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'revenueLift' ? `` : 'text-white'}`}>{accounting.formatMoney(revenueLift)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <ListGroup className="list my--3 bg-primary" flush>
                  { personaData.map((persona, index) => (
                    <ListGroupItem
                      className={`px-0 ${persona.name == activeChartData ? 'bg-white rounded px-2' : 'bg-primary border-0'}`}
                      onClick={() => selectPersona(persona.name)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className={`${persona.name == activeChartData ? '' : 'text-white'}`}>{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color={persona.name == activeChartData ? 'primary' : 'info'}
                            max="100"
                            value={persona.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                    data={chartData[activeChart][activeChartData]}
                    options={chartOptions.options}
                    id="campaign-performance"
                    className="chart-canvas"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedCampaignChart = connect(state => ({
  profile: state.profile,
  account: state.account,
  report: state.report
}))(CampaignsChart);

export default ConnectedCampaignChart;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Database
import { firestore } from "./../../firebase";

// Auth actions
import {
  SET_FILTER_TIMEFRAME,
  SET_READOUT,
  SET_CUSTOMERS,
  SET_ACTIVE_CAMPAIGN
} from 'store/actions';

// Import report blocks
import CampaignsChart from "./components/CampaignsChart.js";

// Query: Campaign Details
async function getCampaigns(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Format query
  let campaignsQuery = {
    "events": [
      {
          "id": "Campaign Activity",
          "math": "hogql",
          "name": "Campaign Activity",
          "type": "events",
          "order": 0,
          "math_hogql": "sum(toFloat(properties.campaign.stats.total_revenue))",
          "properties": [
              {
                  "key": "properties.campaign.version = '0.0.2'",
                  "type": "hogql",
                  "value": null
              },
              {
                  "key": "clientId",
                  "type": "event",
                  "value": [
                      `${account.config.defaultClient}`
                  ],
                  "operator": "exact"
              }
          ],
          "custom_name": "Total Revenue"
      },
      {
          "id": "Campaign Activity",
          "math": "hogql",
          "name": "Campaign Activity",
          "type": "events",
          "order": 1,
          "math_hogql": "sum(toFloat(properties.campaign.stats.total_spend))",
          "properties": [
              {
                  "key": "properties.campaign.version = '0.0.2'",
                  "type": "hogql",
                  "value": null
              },
              {
                  "key": "clientId",
                  "type": "event",
                  "value": [
                      `${account.config.defaultClient}`
                  ],
                  "operator": "exact"
              }
          ],
          "custom_name": "Total Spend"
      }
    ],
    "date_to": endDate,
    "display": "ActionsTable",
    "insight": "TRENDS",
    "interval": "day",
    "breakdown": "properties.campaign.name",
    "date_from": startDate,
    "entity_type": "events",
    "breakdown_type": "hogql"
  };

  // Execute query
  const campaignsResponse = await axios.post(eventsApiUrl, campaignsQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Rollup campaign data
  let campaigns = {};
  for (var i = 0; i < campaignsResponse.data.result.length; i++) {
    // Grab value
    let campaignStat = campaignsResponse.data.result[i];

    // Check if in report
    if(campaigns.hasOwnProperty(campaignStat.breakdown_value)) {
      // Increment
      if(campaignStat.action.custom_name == "Total Revenue") {
        campaigns[campaignStat.breakdown_value].total_sales += campaignStat.aggregated_value;
      }

      // Increment
      if(campaignStat.action.custom_name == "Total Spend") {
        campaigns[campaignStat.breakdown_value].total_spend += campaignStat.aggregated_value;
      }
    } else {
      campaigns[campaignStat.breakdown_value] = {
        campaign_name: campaignStat.breakdown_value,
        return_on_ad_spend: 0.0,
        total_sales: campaignStat.action.custom_name == "Total Revenue" ? campaignStat.aggregated_value : 0.0,
        total_spend: campaignStat.action.custom_name == "Total Spend" ? campaignStat.aggregated_value : 0.0,
        projected_roi: 0.0
      }
    }
  }

  // Construct campaign data
  let campaignData = [];
  for (const campaignName in campaigns) {
    // Set ROI
    campaigns[campaignName].return_on_ad_spend = campaigns[campaignName].total_spend > 0 ? campaigns[campaignName].total_sales / campaigns[campaignName].total_spend : 0.0;

    // Add
    campaignData.push(campaigns[campaignName]);
  }

  // Create readout
  const readout = {
    name: "Campaign Breakdown",
    type: "table",
    data: {
      headers: [
        {
          display: "Campaign Name",
          name: "campaign_name"
        },
        {
          display: "Return on Ad Spend",
          name: "return_on_ad_spend"
        },
        {
          display: "Total Sales",
          name: "total_sales"
        },
        {
          display: "Total Spend",
          name: "total_spend"
        },
        {
          display: "Projected ROI",
          name: "projected_roi"
        }
      ],
      rows: campaignData
    }
  };

  // Return values
  return {
    campaigns: campaignData,
    readout: readout
  };
}

// Query: Campaign Performance
async function getCampaignPerformance(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Format query
  let campaignPerformanceQuery = {
    "events": [
        {
            "id": "Customer Activity",
            "math": "hogql",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "math_hogql": "sum(toFloat(properties.campaign.ad.revenue_lift))",
            "properties": [
                {
                    "key": "type",
                    "type": "event",
                    "value": [
                        "Campaign Conversion"
                    ],
                    "operator": "exact"
                },
                {
                    "key": "properties.campaign.version = '0.0.2'",
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.config.defaultClient}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "date_to": endDate,
    "display": "ActionsLineGraph",
    "insight": "TRENDS",
    "interval": "day",
    "breakdown": "properties.campaign.name",
    "date_from": startDate,
    "entity_type": "events",
    "breakdown_type": "hogql"
  };

  // Execute query
  const campaignPerformanceResponse = await axios.post(eventsApiUrl, campaignPerformanceQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });
  console.log("Campaign Performance Breakdown: ", campaignPerformanceResponse.data);

  // Rollup campaign data
  let campaigns = {};
  for (var i = 0; i < campaignPerformanceResponse.data.result.length; i++) {
    // Grab value
    let campaignStat = campaignPerformanceResponse.data.result[i];

    // Check if in report
    campaigns[campaignStat.breakdown_value] = {
      revenue_lift: _.sum(campaignStat.data)
    }
  }

  return {
    campaigns: campaigns
  };
}

function CampaignPerformance({ profile, account, knowledge, insights, report, model, dispatch }) {
  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Load persona data
  React.useEffect(() => {
    // Campaign Setup
    if (account.initialized) {
      // Sync Campaign Data
      (async () => {
        // Get customer details
        const campaignData = await getCampaigns(account, report.filters.timeframe.startDate, report.filters.timeframe.setEndDate);
        console.log("Campaigns: ", campaignData);

        // Get campaign perforamnce
        const campaignPerformance = await getCampaignPerformance(account, report.filters.timeframe.startDate, report.filters.timeframe.setEndDate);
        console.log("Campaign Performance: ", campaignPerformance);

        // Update campaign performance ROI
        if(campaignPerformance && campaignPerformance.campaigns) {
          // Iterate over campaigns
          for (var i = 0; i < campaignData.readout.data.rows.length; i++) {
            // Check and update
            if(campaignPerformance.campaigns.hasOwnProperty(campaignData.readout.data.rows[i].campaign_name)) {
              // Calculate value
              const projectedSales = campaignData.readout.data.rows[i].total_sales + campaignPerformance.campaigns[campaignData.readout.data.rows[i].campaign_name].revenue_lift;
              const projectedROI = projectedSales / campaignData.readout.data.rows[i].total_spend;

              // Update value
              campaignData.readout.data.rows[i].projected_roi = projectedROI.toFixed(2);
            }
          }
        }

        // Update campaigns
        if(campaignData.campaigns && campaignData.readout && campaignData.campaigns.length > 0) {
          // Get account-client campaigns
          let campaignsBatches = _.chunk(_.keys(campaignPerformance.campaigns), 10);
          let querySnapshot;
          let campaign;
          for (var i = 0; i < campaignsBatches.length; i++) {
            // Get results
            querySnapshot = await firestore.collection("campaigns_v0.9")
                              .where("account", "==", account.config.accountId)
                              .where("client", "==", account.config.defaultClient)
                              .where("data.campaign_name", "in", campaignsBatches[i]).get();

            // Check results
            querySnapshot.forEach((doc) => {
              // Get campaign data
              campaign = doc.data();

              // Update readout values
              for (var k = 0; k < campaignData.readout.data.rows.length; k++) {
                // Check
                if(campaignData.readout.data.rows[k].campaign_name == campaign.data.campaign_name) {
                  // Set readout
                  campaignData.readout.data.rows[k].id = campaign.id;
                  campaignData.readout.data.rows[k].data = campaign.data;
                }
              }
            });
          }

          // Update campaign data
          dispatch({
            type: SET_READOUT,
            payload: campaignData.readout
          });
        }
      })();
    }
  }, [ account ]);

  const handleReactDatetimeChange = (who, date) => {
    // Format date
    const formattedDate = date.format('YYYY-MM-DD');
    console.log("Updating date: ", who, formattedDate);

    // Set startDate
    if(who == "startDate") {
      if(startDate > endDate) {
        // Create new end date
        let newEndDate = startDate.add('days', 7).format('YYYY-MM-DD');
        setEndDate(formattedDate);
      }

      // Set start date
      setStartDate(formattedDate);
    }

    // Set startDate
    if(who == "endDate") {
      setEndDate(formattedDate);
    }

    // Update timeframe
    dispatch({
      type: SET_FILTER_TIMEFRAME,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    });
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  // Select campaign
  const selectCampaign = async (campaign) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_CAMPAIGN,
      payload: campaign
    });

    // Clear customers
    dispatch({
      type: SET_CUSTOMERS,
      payload: []
    });

    // Got to review page
    history.push(`/admin/campaigns/view/${campaign.id}`);
  }

  return (
    <>
      <KnowledgeHeader
        headline="Paid Media Optimization"
        description="Utilize AI personas to better understand paid media spend performance and long-term customer performance."
      />
      <Container className="mt--6" fluid>
        <CampaignsChart />
        <Row>
          <Col xl="12">
            <Row>
              <Col xl="4">
                <Card
                  className="bg-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Form>
                      <Row className="input-daterange datepicker align-items-center">
                        <Col xs={12} sm={6}>
                          <label className="text-white form-control-label">
                            Start Date
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "Date Picker Here",
                              }}
                              value={startDate}
                              timeFormat={false}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes +=
                                  getClassNameReactDatetimeDays(currentDate);
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <label className="text-white form-control-label">
                              End Date
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "Date Picker Here",
                              }}
                              value={endDate}
                              timeFormat={false}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes +=
                                  getClassNameReactDatetimeDays(currentDate);
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8">
                <Card
                  className="bg-gradient-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Ask a questions about these campaigns..."
                          style={{
                            zIndex: 100000000,
                            position: 'absolute',
                            width: '90%',
                            top: 5
                          }}
                          onChange={() => { console.log("changing...")}}
                          disabled
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            zIndex: 200000000,
                            position: 'absolute',
                            right: '2%',
                            top: -2,
                            height: 80,
                            width: 80
                          }}
                        >
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                      <Col
                        md="12"
                        style={{
                          paddingTop: 65
                        }}
                      >
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          new and noteworthy customers
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row>
                          <Col xs="8">
                            <h3 className="mb-0">{ report.readout ? report.readout.name : '' }</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive striped>
                        <thead className="thead-light">
                          <tr>
                            { report.readout.data.headers.map((column, index) => (
                              <th className="sort" data-sort={column.name} scope="col" key={index}>
                                { column.display }
                              </th>
                            ))}
                            <th/>
                          </tr>
                        </thead>
                        <tbody className="list">
                          { report.readout.data.rows.map((campaign, index) => (
                            <tr key={index}>
                              <td className="name">{ campaign.campaign_name }</td>
                              <td className="text-center return_on_ad_spend">{ campaign.return_on_ad_spend.toFixed(2) }</td>
                              <td className="text-center sales">{ accounting.formatMoney(campaign.total_sales) }</td>
                              <td className="text-center spend">{ accounting.formatMoney(campaign.total_spend) }</td>
                              <td className="text-center projected_roi">{ campaign.projected_roi }</td>
                              <td className="text-right">
                                <Button onClick={() => { selectCampaign(campaign) }} color="primary" size="md" type="button" style={{
                                  width: 120
                                }}>
                                  View
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedCampaignPerformance = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(CampaignPerformance);

export default ConnectedCampaignPerformance;

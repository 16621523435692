/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';

// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

// Layouts
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";

function App({ profile, account }) {
  // let faviconUrl = "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://reapcommerce.com&size=16";

  return (
    // <HelmetProvider>
    //   <Helmet>
    //     { (account && account.initialized) ? 
    //       <>
    //         <title>{`Personas - ${account.config.name}`}</title>
    //         <link id="favicon" rel="icon" type="image/x-icon" href={`https://www.google.com/s2/favicons?domain=${account.config.atlasUrl.split(".").slice(1).join(".")}`} />
    //         <meta name="title" content={`Personas - ${account.config.name}`} />
    //         <meta
    //             name="description"
    //             content="Personas - Cognetix"
    //         />
    //         <meta name="author" content="Cognetix" />
    //         <meta property="og:locale" content="en_US" />
    //         <meta property="og:type" content="website" />
    //         <meta property="og:url" content={`Personas - ${account.config.name}`} />
    //         <meta property="og:site_name" content={`Personas - ${account.config.atlasUrl}`} />
    //         <meta property="og:title" content={`Personas - ${account.config.name}`} />
    //         <meta
    //             property="og:description"
    //             content={`Personas - ${account.config.name}`}
    //         />
    //         <meta property="og:image" content={`https://storage.googleapis.com/avant-garde-cdn/axon/accounts/${account.config.accountId}/assets/img/brand/backgrounds/profile.png`} />
    //       </>
    //       : 
    //       <>
    //         <meta name="title" content="Personas - Cognetix" />
    //         <meta
    //             name="description"
    //             content="Personas - Cognetix"
    //         />
    //         <meta name="author" content="Cognetix" />
    //         <meta property="og:locale" content="en_US" />
    //         <meta property="og:type" content="website" />
    //         <meta property="og:url" content="Personas - Cognetix" />
    //         <meta property="og:site_name" content="personas.cognetix.ai" />
    //         <meta property="og:title" content="Personas - Cognetix" />
    //         <meta
    //             property="og:description"
    //             content="Personas - Cognetix"
    //         />
    //         <meta property="og:image" content="https://storage.googleapis.com/avant-garde-cdn/axon/accounts/cognetix/assets/img/brand/backgrounds/profile.png" />
    //       </>
    //     }
    //   </Helmet>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
          <Route path="/" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
  // </HelmetProvider>
  );
}

// Connect to store
const ConnectedApp = connect(state => ({
  profile: state.profile,
  account: state.account
}))(App);

export default ConnectedApp;